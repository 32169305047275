import React, { useEffect, useState } from "react";
import { useAuth } from "hooks/use-auth";
import {
    getUserOnboardingSteps,
    updateUserOnboardingStep
} from "api/user/profile";
import {
    EOnboardingStepStatus,
    IOnboardingSessionStep
} from "lib/types/onboarding.types";
import HeaderBannerLayout from "components/common/v1/HeaderBannerLayout";
import BookingsCard from "components/dashboard-widgets/BookingsCard";
import ScheduleOnboardingSession from "components/dashboard-widgets/ScheduleOnboardingSession";
import AdvisorLevel from "components/dashboard-widgets/AdvisorLevel";
import RecommendedNextSteps from "components/dashboard-widgets/RecommendedNextSteps";

import { useSearchParams } from "next/navigation";
import { getNumberOfBookingsForUser } from "api/bookings/bookings";
import { BookingInformation } from "components/dashboard-widgets/BookingInformation";
import CompanyInfoCard from "components/dashboard-widgets/CompanyInfoCard";

export const ONBOARDING_SESSION = "onboarding-session";

export default function HomePage() {
    const { token, userProfile, isFeatureEnabled } = useAuth();
    const v2Enabled = isFeatureEnabled("dashboard_v2");
    // attended_onboarding FF will be true for all users with the attended_onboarding admin tag
    const attendedOnboarding = isFeatureEnabled("attended_onboarding");

    const userAnalyticsBlock = {
        email: userProfile.email,
        first_name: userProfile.firstName,
        last_name: userProfile.lastName
    };
    const personalEmail = userProfile.personalEmail;

    const [step, setStep] = useState<IOnboardingSessionStep | undefined>(
        undefined
    );
    const fetchData = async () =>
        getUserOnboardingSteps(token).then(result => {
            const step = result?.find(
                (step: any) => step.slug === ONBOARDING_SESSION
            );
            setStep(step);
        });
    useEffect(() => {
        fetchData();
    }, []);

    const [numBookings, setNumBookings] = useState<number | undefined>(
        undefined
    );
    useEffect(() => {
        if (!token) return;
        getNumberOfBookingsForUser(token).then(res => setNumBookings(res));
    }, [token]);

    function handleEventScheduled(invitee_id: string, event_id: string) {
        const onboardingSessionStep = step as IOnboardingSessionStep;

        onboardingSessionStep.metadata.invitee_id = invitee_id;
        onboardingSessionStep.metadata.event_id = event_id;
        // We won't actually be using the `step.status` value in the UI anymore, but we'll set step
        // status=COMPLETED after they schedule the call to provide consistent behavior in case where we
        // roll back the FE code. (In old UI, status was set to "Complete" when user clicked Next Step
        // button, which no longer exists.)
        onboardingSessionStep.status = EOnboardingStepStatus.COMPLETED;

        updateUserOnboardingStep(onboardingSessionStep, token).then(res =>
            fetchData()
        );
    }

    // We need a way to force the showing of the calendly widget even in cases where it would normally
    // be hidden so we can link directly to it. We'll use a query param for this.
    const searchParams = useSearchParams();
    const calendly = searchParams.get("calendly");
    const forceCalendlyToShow = calendly === "true";

    // We could use start_time to detect if call is scheduled, but we'll use invitee_id for backward compatibility with users who scheduled before new BE code was introduced.
    const callScheduled = Boolean(step?.metadata?.invitee_id);
    // If scheduled for datetime is before today, call not yet completed.
    // NOTE: For users who scheduled during old onboarding (and therefore will never have a start_time set) we can't actually know if the call was completed or not.
    // We've decided to assume it was completed so that the calendly widget will not stick around forever, but be careful of this behavior when relying on the callCompleted variable for other uses.
    const callCompleted = callScheduled
        ? step?.metadata?.start_time
            ? new Date(step?.metadata?.start_time) < new Date()
            : true
        : false;
    const hasTwoOrMoreBookings = numBookings !== undefined && numBookings > 1;
    const isLoading = step === undefined || numBookings === undefined;

    // In the loading state, if we are not forcing the calendly to show, it's most likely we'll end up hiding the Calendly onboarding widget (b/c most users have done it) so we'll assume that layout.
    // If we are forcing the calendly to show, we know it will end up on the page so we'll display it in the loading state
    const showCalendly =
        forceCalendlyToShow ||
        (!callCompleted &&
            !attendedOnboarding &&
            (!hasTwoOrMoreBookings || callScheduled) &&
            !isLoading);

    return (
        <HeaderBannerLayout
            title="Home"
            headerText={`Welcome${
                userProfile?.firstName ? `, ${userProfile.firstName}!` : ""
            }`}
        >
            {!isLoading && (
                <div className="hidden" data-testid="loading-done" />
            )}
            <div className="max-w-[1056px] mr-auto" data-testid="new_home_page">
                {v2Enabled ? (
                    <>
                        {showCalendly && (
                            <ScheduleOnboardingSession
                                step={step}
                                onUpdateOnboardingSession={handleEventScheduled}
                                userAnalyticsBlock={userAnalyticsBlock}
                                personalEmail={personalEmail}
                            />
                        )}
                        <AdvisorLevel />
                        <BookingsCard />
                        <BookingInformation userProfile={userProfile} />
                        <RecommendedNextSteps
                            showLinkToCalendly={showCalendly}
                        />
                    </>
                ) : (
                    <>
                        {showCalendly && (
                            <ScheduleOnboardingSession
                                step={step}
                                onUpdateOnboardingSession={handleEventScheduled}
                                userAnalyticsBlock={userAnalyticsBlock}
                                personalEmail={personalEmail}
                            />
                        )}

                        <AdvisorLevel />
                        {!showCalendly && <BookingsCard />}
                        <RecommendedNextSteps
                            showLinkToCalendly={showCalendly}
                        />
                        {showCalendly && <BookingsCard />}
                        <CompanyInfoCard userProfile={userProfile} />
                    </>
                )}
            </div>
        </HeaderBannerLayout>
    );
}
