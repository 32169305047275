import DOMPurify from "dompurify";
import { Copy } from "phosphor-react";
import React from "react";
import { Tooltip } from "react-tooltip";
import { DateTime } from "luxon";

import {
    AnalyticsSlugsBookingsPlatform,
    bookingsPlatformTrack
} from "components/supplier-database/bookings-platform/analytics/BookingsPlatformAnalytics";

const CopyButtonQuote = ({
    contentToCopy = "",
    key = Date.now().toString() + Math.random(),
    hideDelay = 1500,
    activeElement = null,
    analyticsData = {}
}: {
    contentToCopy?: string | undefined;
    key?: string;
    hideDelay?: number;
    activeElement?: React.ReactElement | null;
    analyticsData?: any;
}): JSX.Element => {
    const addNewLineAfterTags = (htmlString: string) => {
        // Regular expression to match HTML tags
        const regex = /(<\/[plidiv][^>]*>)/gi;
        const regexBr = /(<\/[br][^>]*>)/gi;

        // Replace each HTML tag with the tag followed by a new line character,
        // and remove duplicated new lines
        const result = htmlString
            ?.replace(regex, "$1\n")
            .replace(/\n+/g, "\n")
            .replace(regexBr, "$1\n");

        return result;
    };
    const renderElement = (contentToCopy: string) => {
        if (!contentToCopy)
            return null; /*if contentToCopy is not set we do not render button*/

        const contentToCopyHtml = DOMPurify.sanitize(contentToCopy, {
            USE_PROFILES: { html: true }
        });
        const contentToCopyPreparedToPlainText =
            addNewLineAfterTags(contentToCopy);

        const plainText = DOMPurify.sanitize(contentToCopyPreparedToPlainText, {
            ALLOWED_TAGS: ["br"]
        })
            .replace(/&nbsp;/g, " ")
            .replace(/\n+/g, "\n")
            .replaceAll("\n<br><br>", "\n\n")
            .replaceAll("\n<br>", "\n")
            .replaceAll("<br>", "\n")
            .replaceAll("&amp;", "&");

        const handleCopy = async () => {
            const clipboardItem = {
                "text/plain": new Blob([plainText], {
                    type: "text/plain"
                }),
                "text/html": new Blob([contentToCopyHtml], {
                    type: "text/html"
                })
            };
            if (typeof ClipboardItem !== "undefined") {
                await navigator.clipboard.write([
                    new ClipboardItem(clipboardItem)
                ]);
            } else {
                const { ClipboardItem, write } = await import(
                    "clipboard-polyfill"
                );
                const item = new ClipboardItem(clipboardItem);
                await write([item]);
            }

            const handledAnalyticsData = {
                ...analyticsData,
                copied_content: contentToCopyHtml,
                copied_datetime: DateTime.now().toISO()
            };
            bookingsPlatformTrack(
                AnalyticsSlugsBookingsPlatform.BOOKINGS_PLATFORM__COPY_RATE_CLICKED,
                handledAnalyticsData
            );
        };

        const ActiveElement = activeElement
            ? () =>
                  React.cloneElement(activeElement, {
                      onClick: handleCopy,
                      "data-tooltip-id": key
                  })
            : null;

        return activeElement ? (
            // @ts-ignore
            <ActiveElement />
        ) : (
            <button
                className="w-6 h-6 md:w-8 md:h-8 shrink-0 flex items-center justify-center rounded text-secondary cursor-pointer"
                data-tooltip-id={key}
                onClick={handleCopy}
            >
                <Copy size={18} />
            </button>
        );
    };

    return (
        <>
            <Tooltip
                content="Copied!"
                openOnClick={true}
                className="opaque-tooltip bg-black text-white "
                id={key}
                delayHide={hideDelay}
            />
            {renderElement(contentToCopy)}
        </>
    );
};

export default CopyButtonQuote;
