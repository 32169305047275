import React, { PropsWithChildren } from "react";
import { UserProfile } from "api/user/profile";
import CopyButtonQuote from "components/common/Copybutton/CopyButtonQuote";
import Link from "next/link";
import { OnboardingWidgetCompanyInfoCopied } from "analytics/onboarding/GettingStartedAnalytics";

export const BookingInformation = ({
    userProfile
}: {
    userProfile: UserProfile;
}) => {
    const userAnalyticsBlock = {
        email: userProfile.email,
        first_name: userProfile.firstName,
        last_name: userProfile.lastName
    };

    const BookingInformationItem = ({
        label,
        copyContent,
        contentType,
        children
    }: PropsWithChildren<{
        label: string;
        copyContent?: string;
        contentType?: string;
    }>) => {
        return (
            <div key={label}>
                <span className="flex justify-start items-center pb-1">
                    <h5 className="text-secondary font-bold tracking-wide text-small">
                        {label}
                    </h5>
                    {copyContent && (
                        <CopyButtonQuote
                            contentToCopy={copyContent}
                            analyticsData={() =>
                                OnboardingWidgetCompanyInfoCopied({
                                    ...userAnalyticsBlock,
                                    contentType: contentType || ""
                                })
                            }
                        />
                    )}
                </span>
                <div
                    className={`text-main whitespace-pre-line ${
                        copyContent ? "-mt-2" : ""
                    }`}
                >
                    {children}
                </div>
            </div>
        );
    };

    return (
        <div className="[@media(min-width:1160px)]:row-start-2 flex flex-col [@media(min-width:1160px)]:col-start-2 mb-8">
            <div className="flex-1 bg-white rounded border border-stroke shadow-card p-6 md:p-8">
                <div className="grid gap-[9px]">
                    <h2 className="text-header font-bold">
                        Booking information
                    </h2>
                    <div className="flex flex-col md:flex-row gap-8 md:gap-0">
                        <div className="flex flex-col gap-6 md:gap-4 grow">
                            <BookingInformationItem
                                label="IATA#"
                                copyContent="33520476"
                                contentType="IATA"
                            >
                                33520476
                            </BookingInformationItem>
                            <BookingInformationItem label="BOOK IN PORTAL">
                                Travel partners, rates, and commission details
                                all in the{" "}
                                <Link href="/partners" className="text-link">
                                    Partners
                                </Link>{" "}
                                section
                            </BookingInformationItem>
                            <BookingInformationItem label="BOOK OUTSIDE PORTAL">
                                You can book directly or through trusted 3rd
                                parties.
                                <br /> Visit{" "}
                                <Link href="/partners" className="text-link">
                                    Partners
                                </Link>{" "}
                                to see instructions for hotels, cruises, tours,
                                and more.
                                <div className="bg-blueLight rounded-md px-6 py-4 mt-6 md:mt-6">
                                    Remember when booking outside Portal:
                                    <ol className="list-decimal ml-6">
                                        <li>
                                            Confirm the rate is commissionable
                                            with partner prior to booking
                                        </li>
                                        <li>Include IATA# on booking</li>
                                        <li>
                                            Forward confirmation to{" "}
                                            <a
                                                className="text-link"
                                                href="mailto:commissions@fora.travel"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                commissions@fora.travel
                                            </a>{" "}
                                            to get paid
                                        </li>
                                    </ol>
                                </div>
                            </BookingInformationItem>
                        </div>
                        <div className="min-w-8 grow hidden md:block"></div>
                        <div className="flex flex-col gap-6 md:gap-8 min-w-[218px]">
                            <BookingInformationItem
                                label="MAILING ADDRESS"
                                copyContent="Fora Travel, Inc.<br>
                                228 Park Ave South #53272<br>
                                New York, NY 10003-1502"
                                contentType="Mailing Address"
                            >
                                Fora Travel, Inc.
                                <br />
                                228 Park Ave South #53272
                                <br />
                                New York, NY 10003-1502
                            </BookingInformationItem>

                            <BookingInformationItem
                                label="PHONE NUMBER"
                                copyContent="+1 844-409-3672"
                                contentType="Phone Number"
                            >
                                +1 844-409-FORA (3672)
                            </BookingInformationItem>
                            <BookingInformationItem label="MORE...">
                                <ul>
                                    <li>
                                        Ask{" "}
                                        <Link
                                            href="/sidekick"
                                            className="text-link"
                                        >
                                            Sidekick
                                        </Link>
                                    </li>
                                    <li>
                                        Visit{" "}
                                        <Link
                                            href="/help"
                                            className="text-link"
                                        >
                                            Help Center
                                        </Link>{" "}
                                        for Support
                                    </li>
                                    <li>
                                        Read the{" "}
                                        <Link
                                            href="/community-guidelines"
                                            className="text-link"
                                        >
                                            Community Guidelines
                                        </Link>
                                    </li>
                                </ul>
                            </BookingInformationItem>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
